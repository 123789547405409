import * as defaultColors from '../../default/tokens/color';
export * from '../../default/tokens/color';

export const backgroundColoredColors = {
  ...defaultColors.backgroundColoredColors,
  backgroundVarOne: '{{ neutralColors.neutral100 }}',
  backgroundVarTwo: '{{ neutralColors.neutral100 }}',
  backgroundVarThree: '{{ neutralColors.neutral100 }}',
  backgroundVarFour: '{{ neutralColors.neutral100 }}',
  backgroundVarFive: '{{ neutralColors.neutral100 }}',
  backgroundVarSix: '{{ neutralColors.neutral100 }}',
};

export const backgroundSitecoreColors = {
  ...defaultColors.backgroundSitecoreColors,
  backgroundCMSVarOne: '{{ neutralColors.neutral100 }}',
  backgroundCMSVarTwo: '{{ neutralColors.neutral100 }}',
  backgroundCMSVarThree: '{{ neutralColors.neutral100 }}',
  backgroundCMSVarFour: '{{ neutralColors.neutral100 }}',
  backgroundCMSVarFive: '{{ neutralColors.neutral100 }}',
  backgroundCMSVarSix: '{{ neutralColors.neutral100 }}',
};

export const brandColors = {
  ...defaultColors.brandColors,
  brandOrange: '#EA714F',
};

export const neutralColors = {
  ...defaultColors.neutralColors,
  neutralWhite: '#FFF',
  neutral25: '#FCFAFA', // deprecated in rebranding but still used in some places, so we set it to neutral50
  neutral50: '#FCFAFA',
  neutral100: '#F8F6F6',
  neutral200: '#F3F0F0', // deprecated in rebranding but still used in some places, so we set it to neutral300
  neutral300: '#F3F0F0',
  neutral400: '#DFDCDC',
  neutral500: '#7B7575', // deprecated in rebranding but still used in some places, so we set it to neutral800
  neutral800: '#7B7575',
  neutral900: '#2F2D2D',
  neutralBlack: '#000',
};

export const secondaryColors = {
  ...defaultColors.secondaryColors,
  accentGreen100: '#e3faea',
  accentGreen200: '#c0eaca',
  accentGreen300: '#84dc99',
  accentGreen600: '#009b65',
  accentGreen700: '#007250',
  accentGreen800: '#00593f',
  enecoRed900: '#821034',
  enecoRed800: '#bf0639',
  enecoRed700: '#d21242',
  enecoRed600: '#e5384c',
  orange500: '#ea714f',
  orange400: '#ff9363',
  orange300: '#ffba8f',
  orange100: '#ffe7dc',
  green700: '{{ secondaryColors.accentGreen600 }}',
};

export const textColors = {
  ...defaultColors.textColors,
  textBrand: '{{ secondaryColors.accentGreen700 }}',
};

export const globalThemeColors = {
  ...defaultColors.globalThemeColors,
  ...neutralColors,
  ...brandColors,
  ...backgroundColoredColors,
  ...backgroundSitecoreColors,
  ...textColors,
};
