import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_UserAccounts_PasswordChangeRequest } from '@monorepo-types/dc';
import { RequestModels_UserAccounts_UsernameChangeRequest } from '@monorepo-types/dc';
import { UserAccounts_PushPreferencesRequestModel } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type SetUsernameByCustomerId = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody?: RequestModels_UserAccounts_UsernameChangeRequest;
};
/**
 * SetUsernameByCustomerId
 * Sets the username by customer identifier.
 * @returns any Success
 */
export function setUsernameByCustomerId(
  { businessUnit, label, customerId, requestBody }: SetUsernameByCustomerId,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PATCH',
      path: `/dxpweb/${businessUnit}/${label}/useraccounts/customers/${customerId}/username`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type ChangePasswordByCustomerId = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody?: RequestModels_UserAccounts_PasswordChangeRequest;
};
/**
 * ChangePasswordByCustomerId
 * Changes the password by customer identifier.
 * @returns any Success
 */
export function changePasswordByCustomerId(
  { businessUnit, label, customerId, requestBody }: ChangePasswordByCustomerId,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PATCH',
      path: `/dxpweb/${businessUnit}/${label}/useraccounts/customers/${customerId}/password`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type DeleteUserAccount = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
};
/**
 * DeleteUserAccount
 * Deletes a useraccount
 * @returns void
 */
export function deleteUserAccount(
  { businessUnit, label, customerId }: DeleteUserAccount,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'DELETE',
      path: `/dxpweb/${businessUnit}/${label}/useraccounts/customers/${customerId}`,
      errors: {
        400: 'When the validation is incorrect or the backend returns a functional error.',
        404: 'The customer does not have a useraccount',
      },
    },
    requestConfig,
  );
}

type RegisterPushPreferencesUserAccount = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody: UserAccounts_PushPreferencesRequestModel;
};
/**
 * RegisterPushPreferencesUserAccount
 * Set the push preferences of a user
 * @returns void
 */
export function registerPushPreferencesUserAccount(
  { businessUnit, label, customerId, requestBody }: RegisterPushPreferencesUserAccount,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/${businessUnit}/${label}/useraccounts/customers/${customerId}/pushpreferences`,
      body: requestBody,
      errors: { 400: 'When the validation is incorrect or the backend returns a functional error.' },
    },
    requestConfig,
  );
}
