import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { Airship, PromptPermissionFallback } from '@ua/capacitor-airship';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { useRouter } from 'next/router';

import Logger from '@common/log';
import { useCustomerGetCustomerProfile, useUserAccountsRegisterPushPreferencesUserAccount } from '@dc/hooks';
import useDC from '@dc/useDC';
import { UserAccounts_PushPreferencesRequestModel } from '@monorepo-types/dc';

type Props = {
  setSystemNotification: (toggleAction?: boolean) => Promise<boolean | null>;
  fetchSystemNotificationStatus: () => Promise<boolean>;
  hasError: boolean;
};

const PushNotificationsContext = createContext<Props | null>(null);

const PushNotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: customer } = useCustomerGetCustomerProfile();
  const { businessUnit, label, customerId } = useDC();
  const { send, isError } = useUserAccountsRegisterPushPreferencesUserAccount();
  const router = useRouter();

  const [hasError, setHasError] = useState(false);

  const hasPushNotificationsFeature = customer?.features?.some(feature => feature === 'BEPushNotifications');

  const fetchSystemNotificationStatus = useCallback(async () => {
    try {
      if (Capacitor.isNativePlatform() && hasPushNotificationsFeature) {
        const permissionStatus = await Airship.push.getNotificationStatus();
        const isEnabled = permissionStatus.notificationPermissionStatus === 'granted';

        // Check if there is a difference between local storage & system notifications status.
        // When there is a difference, we update the push preference value in DC.
        const { value } = await Preferences.get({ key: 'System_notifications_status' }).then(value => value);
        const boolValue = value === 'true';
        if (boolValue !== isEnabled && customerId) {
          await send({
            label,
            customerId,
            businessUnit,
            requestBody: {
              enableGeneralPushPreferences: isEnabled,
            } as UserAccounts_PushPreferencesRequestModel,
          });

          const namedUserId = await Airship.contact.getNamedUserId();
          // Create named user in Airship, this way we can send out messages to specific users (based on customer id).
          if (!namedUserId && isEnabled) {
            await Airship.contact.identify(`${customerId}`);
            // Remove named user if user disables notifications
          } else if (namedUserId && !isEnabled) {
            await Airship.contact.reset();
          }
        }

        await Preferences.set({ key: 'System_notifications_status', value: isEnabled ? 'true' : 'false' });

        return isEnabled;
      }

      return false;
    } catch (e) {
      setHasError(true);
      Logger.error('bSQIm_', 'Something went wrong fetching system notification status.', e);

      return false;
    }
  }, [businessUnit, customerId, hasPushNotificationsFeature, label, send]);

  useEffect(() => {
    setHasError(isError);
  }, [isError]);

  useEffect(() => {
    setHasError(false);
  }, [router?.asPath]);

  useEffect(() => {
    async function getNotificationStatus() {
      await fetchSystemNotificationStatus();
    }

    getNotificationStatus();
  }, [fetchSystemNotificationStatus]);

  const openAppSettings = useCallback(async () => {
    try {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.AppNotification,
        optionIOS: IOSSettings.App,
      });
    } catch (e) {
      setHasError(true);
      Logger.error('mAQ6FA', 'Something went wrong opening system notifications.', e);
    }
  }, []);

  const setSystemNotification = useCallback(
    async (toggleAction?: boolean) => {
      try {
        if (Capacitor.isNativePlatform() && hasPushNotificationsFeature) {
          // If notifications are enabled, we can only disable them in the system settings.
          const status = await fetchSystemNotificationStatus();
          if (status && toggleAction) {
            await openAppSettings();
          }

          return await Airship.push.enableUserNotifications({
            fallback: toggleAction ? PromptPermissionFallback.SystemSettings : undefined,
          });
        }

        return null;
      } catch (e) {
        setHasError(true);
        Logger.error('cvlget', 'Something went wrong enabling or disabling system notifications.', e);

        return null;
      }
    },
    [fetchSystemNotificationStatus, hasPushNotificationsFeature, openAppSettings],
  );

  return (
    <PushNotificationsContext.Provider value={{ setSystemNotification, fetchSystemNotificationStatus, hasError }}>
      {children}
    </PushNotificationsContext.Provider>
  );
};

const usePushNotificationsContext = () => {
  const context = useContext(PushNotificationsContext);

  if (context === null) {
    throw new Error('usePushNotificationsContext must be within the PushNotificationsProvider');
  }

  return context;
};

export { usePushNotificationsContext, PushNotificationsProvider };
