import { createMutationHook, collapseParams } from '../client';
import {
  setUsernameByCustomerId,
  changePasswordByCustomerId,
  deleteUserAccount,
  registerPushPreferencesUserAccount,
} from '../services/UserAccountsService';

export const useUserAccountsSetUsernameByCustomerId = createMutationHook(
  collapseParams(collapseParams(setUsernameByCustomerId, 'requestBody', 'data'), 'data', 'username', 'primaryEmail'),
  {
    injectables: ['label', 'customerId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['username', 'primaryEmail'] },
    ],
  },
);

export const useUserAccountsChangePasswordByCustomerId = createMutationHook(
  collapseParams(
    collapseParams(changePasswordByCustomerId, 'requestBody', 'data'),
    'data',
    'oldPassword',
    'newPassword',
  ),
  {
    injectables: ['label', 'customerId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['oldPassword', 'newPassword'] },
    ],
  },
);

export const useUserAccountsDeleteUserAccount = createMutationHook(deleteUserAccount, {
  injectables: ['label', 'customerId', 'businessUnit'],
  flattenData: false,
});

export const useUserAccountsRegisterPushPreferencesUserAccount = createMutationHook(
  registerPushPreferencesUserAccount,
  { injectables: ['label', 'customerId', 'businessUnit'], flattenData: false },
);
