export * from '../../default/tokens/border';

export const radii = {
  none: '0',
  xs: '{{ GRID }}px',
  s: '{{ GRID * 2 }}px',
  m: '{{ GRID * 4 }}px',
  l: '{{ GRID * 6 }}px',
  xl: '{{ GRID * 8 }}px',
  round: '9999px',
};
